import './index.scss';
import '../../App.scss';
import AnimatedLetters from '../AnimatedLetters';
import Loader from 'react-loaders';
import { useEffect, useState } from 'react';
import CloudPractitionerBadge from '../../assets/images/aws-certified-cloud-practitioner.png'
import SolutionArchitectAssociateBadge from '../../assets/images/aws-certified-solutions-architect-associate.png'

const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    return (
        <>
            <div className='container about-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
                            idx={15}
                        />
                    </h1>
                    <p>
                        Hi, I'm Stefan. I would describe myself as a optimistic, motivated, hard-working individual with a goal-oriented mindset.
                        My love for automated, embedded systems that have the potential to control the world around us has led me to start my career in Computer Engineering.
                        I am crazy about astronomy, IoT devices, home automation and cryptography.
                    </p>

                    <h4>
                        Education
                    </h4>

                    <p>I obtained my Computer Engineering degree with distinction as a member of the class of 2021 at the University of Pretoria.
                        During my studies, I was exposed to programming in C++, Java, and Python as well as to the design and analysis of electronic systems.
                        <br />
                        My final year project entailed the design and implementation of an Advanced Driving Assistance System (ADAS).
                    </p>
                    <p>
                        I received the Vice-Chancellor Discretionary Merit Award in 2018 and was on the EBIT Dean's Merit List in 2018, 2019, 2020 and 2021.
                    </p>

                    <p>
                        I attended highschool at Hoërskool Garsfontein in Pretoria, where I was the vice president of academics and the top academic achiever in my Grade 12 year.
                        I obtained a NSC average of 94.13% and passed with 9 distinctions.
                    </p>
                    <h4>
                        Employment
                    </h4>
                    <p>I was employed as Graduate Software Engineer at Gendac from February to October 2022, where I learnt a great deal.</p>
                    <h4>
                        Certifications
                    </h4>
                    <p>
                        <a href='https://www.credly.com/badges/a421319a-6756-43c5-a549-88d4bad9ef9f/public_url'>
                            <img src={CloudPractitionerBadge} width="150" height="150" alt="AWS Certified Cloud Practitioner" />
                        </a>
                        <a href='https://www.credly.com/badges/3f527585-a02d-4e54-8345-f5a30fb72fa9/public_url'>
                            <img src={SolutionArchitectAssociateBadge} width="150" height="150" alt="AWS Certified Solutions Architect - Associate" />
                        </a>
                    </p>
                </div>
            </div>
            <Loader type="pacman" />
        </>

    )
}

export default About