import { useEffect, useState } from 'react'
import LogoTitle from '../../assets/images/logo-s.png'
import { Link } from 'react-router-dom'
import AnimatedLetters from '../AnimatedLetters'
import Logo from './Logo'
import './index.scss'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const nameArray = ['t', 'e', 'f', 'a', 'n', ',']
  const jobArray = [
    'a',
    ' ',
    'c',
    'o',
    'm',
    'p',
    'u',
    't',
    'e',
    'r',
    ' ',
    'e',
    'n',
    'g',
    'i',
    'n',
    'e',
    'e',
    'r',
    '.',
  ]

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  }, [])

  return (
    <div className="container home-page">
      <div className="text-zone">
        <h1>
          <span className={letterClass}>H</span>
          <span className={`${letterClass} _12`}>i,</span>
          <br />
          <span className={`${letterClass} _13`}>I</span>
          <span className={`${letterClass} _14`}>'m</span>
          <img src={LogoTitle} alt="developer" />
          <AnimatedLetters
            letterClass={letterClass}
            strArray={nameArray}
            idx={15}
          />
          <br />
          <AnimatedLetters
            letterClass={letterClass}
            strArray={jobArray}
            idx={21}
          />
        </h1>
        <h2>
          Hoërskool Garsfontein | University of Pretoria | Gendac Software
          Engineering
        </h2>
        <Link to="/contact" className="flat-button">
          CONTACT ME
        </Link>
        <a
          target="_blank"
          href="https://blog.stefanbuys.com"
          rel="noreferrer"
          className="flat-button"
        >
          BLOG
        </a>
      </div>
      <Logo />
    </div>
  )
}

export default Home
